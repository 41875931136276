import { Injectable, inject } from '@angular/core';
import { AppItem } from '@app/components/layouts/menu.types';
import {
  BIOGAS_API_ROUTES,
  USERS_API_ROUTES,
} from '@app/constants/api-route.constant';
import { CLIENTURL } from '@app/constants/app.constant';
import { HttpClientService } from '@app/services/http-client/http-client.service';
import {
  ClientList,
  ClientUser,
  LicenceHours,
} from '@app/views/users/users-overview/users.types';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { USER_API_ROUTES } from './../../../constants/api-route.constant';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserApiService {
  httpClientService = inject(HttpClientService);

  getCurrentUser(): Observable<ClientUser> {
    return this.httpClientService.get(
      `${CLIENTURL}/${USERS_API_ROUTES.GET_USERS}/${USER_API_ROUTES.GET_ME}`,
    );
  }

  getUser(userId: number): Observable<ClientUser> {
    return this.httpClientService.get(
      `${CLIENTURL}/${USERS_API_ROUTES.GET_USERS}/${userId}`,
    );
  }

  getClients(): Observable<Array<ClientList>> {
    return this.httpClientService.get(`${USER_API_ROUTES.GET_CLIENTS}`);
  }

  getApps(): Observable<Array<AppItem>> {
    return this.httpClientService.get(environment.appsAPI);
  }

  getBiogasStatus(): Observable<boolean> {
    return this.httpClientService.get(
      `${CLIENTURL}/${BIOGAS_API_ROUTES.GET_BIOGAS}/${BIOGAS_API_ROUTES.GET_ACTIVE}`,
    );
  }

  getLicenceData(): Observable<LicenceHours> {
    return this.httpClientService.get(
      `${USER_API_ROUTES.GET_CLIENTS}/${CLIENTURL}/${USER_API_ROUTES.GET_HOURS}`,
    );
  }
}
