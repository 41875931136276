import { Injectable, computed, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import { AppItem } from '@app/components/layouts/menu.types';
import { APP_CONSTANTS } from '@app/constants/app.constant';
import { GA_EVENTS } from '@app/constants/google-analytics-events.constant';
import { LOCAL_STORAGE_CONSTANT } from '@app/constants/localstorage.constant';
import { USER_ROUTE } from '@app/constants/routes/route-paths.constant';
import { AnalyticsService } from '@app/services/google-analytics/analytics.service';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import {
  ClientList,
  ClientUser,
  LicenceHours,
} from '@app/views/users/users-overview/users.types';
import { tap } from 'rxjs';
import { CurrentUserApiService } from '../current-user-api/current-user-api.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserStateService {
  private userApiService = inject(CurrentUserApiService);
  private localStorageService = inject(LocalStorageService);
  private router = inject(Router);
  private analyticsService = inject(AnalyticsService);

  returnURL: string;

  clientListSignal = signal<ClientList[]>([]);
  selectedClientSignal = computed(() => {
    const user = this.clientListSignal().filter((client) => client.selected);
    return user?.length ? user[0] : this.clientListSignal()[0];
  });

  selectedUser = signal<ClientUser>(null);

  clientId = computed(() => this.selectedClientSignal().urlName);

  appListSignal = signal<AppItem[]>([]);

  biogasStatusListSignal = signal<boolean>(false);

  usedHoursListSignal = signal<LicenceHours>(null);

  currentUserStateChange = false;

  biogasStatus = false;

  updateSelectedClient(url: string) {
    const clients = this.clientListSignal().map((client) => {
      return {
        ...client,
        selected: false,
      };
    });
    const index = clients.findIndex((client) => client.urlName === url);
    if (index >= 0) clients[index].selected = true;
    this.clientListSignal.set(clients);
    this.currentUserStateChange = true;
    this.setUser();
  }

  setAppList() {
    this.userApiService
      .getApps()
      .pipe(
        tap((apps: AppItem[]) => {
          this.appListSignal.set(apps);
        }),
      )
      .subscribe();
  }

  setClientList(redirect = false) {
    this.userApiService
      .getClients()
      .pipe(
        tap((clientList: ClientList[]) => {
          this.clientListSignal.set(clientList);
        }),
      )
      .subscribe(() => this.setUser(redirect));
  }

  getClients() {
    return this.userApiService.getClients().pipe(
      tap((clientList: ClientList[]) => {
        this.clientListSignal.set(clientList);
      }),
    );
  }

  setRedirectURL(url: string) {
    this.returnURL = url;
  }

  setUser(redirect = false) {
    this.userApiService.getCurrentUser().subscribe((user) => {
      this.analyticsService.setUserId(user.id);
      this.analyticsService.setUserProperty('user_group', user.clientUrl);
      if (redirect) {
        this.analyticsService.trackEvent(GA_EVENTS.LOGIN);
      }
      this.localStorageService.set(LOCAL_STORAGE_CONSTANT.USER_DATA, user);
      this.setBiogasStatus().subscribe();
      const path =
        this.hasAdminAccess &&
        window.innerWidth > APP_CONSTANTS.MOBILE_DEVICE_WIDTH
          ? USER_ROUTE.DASHBOARD
          : USER_ROUTE.OVERVIEW;
      const url = this.returnURL ? this.returnURL : path;
      if (redirect) this.router.navigate([url]);
      if (this.currentUserStateChange) {
        this.currentUserStateChange = false;
        this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.RECENT_REPORTS);
        location.reload();
      }
    });
  }

  get hasAdminAccess() {
    const user = this.getCurrentUser();
    return (
      user.auth.adminTime ||
      user.auth.adminCustomer ||
      user.auth.adminProduct ||
      user.auth.adminUser ||
      user.auth.admin ||
      user.auth.handelTime
    );
  }

  getCurrentUser(): ClientUser {
    return this.localStorageService.get(LOCAL_STORAGE_CONSTANT.USER_DATA);
  }

  getBiogasStatus(): boolean {
    return this.localStorageService.get(LOCAL_STORAGE_CONSTANT.BIOGAS_STATUS);
  }

  setBiogasStatus() {
    return this.userApiService.getBiogasStatus().pipe(
      tap((status: boolean) => {
        this.biogasStatus = status;
        this.biogasStatusListSignal.set(status);
        this.localStorageService.set(
          LOCAL_STORAGE_CONSTANT.BIOGAS_STATUS,
          status,
        );
      }),
    );
  }

  setLicenceInfo() {
    this.userApiService
      .getLicenceData()
      .pipe(
        tap((hour) => {
          this.usedHoursListSignal.set(hour);
        }),
      )
      .subscribe();
  }
}
